<template>
	<div class="detail">
		<el-dialog :title="`${row.staff_name}激励详情`" :visible.sync="visible" width="1000px" :before-close="handleClose">
			<div class="scroll" style="min-height: 500px;" v-loading="loading">
				<p style="margin: 0 0 15px;">
					年度目标
					<el-select size="mini" style="width: 100px;margin-left: 15px;" v-model="year" @change="getDetail()" placeholder="请选择">
						<el-option v-for="(item, key) in yearList" :key="key" :label="item" :value="item"></el-option>
					</el-select>
				</p>
				<div class="table">
					<el-table
						v-loading="loading"
						:data="yearData"
						style="width: 100%"
						:header-cell-style="{
							color: '#333',
							fontSize: '14px',
							backgroundColor: '#F6F7FB',
							fontWeight: 'normal'
						}"
					>
						<el-table-column prop="name" label="年度"></el-table-column>
						<el-table-column prop="target" label="年度目标"></el-table-column>
						<el-table-column prop="finish" label="完成进度">
							<template slot-scope="scope">
								{{ parseInt((scope.row.target * scope.row.finish) / 100) }} ({{ scope.row.finish }}%)
							</template>
						</el-table-column>
						<el-table-column label="是否完成">
							<template slot-scope="scope">
								{{ scope.row.is_complete == 1 ? '是' : '否' }}
							</template>
						</el-table-column>
						<el-table-column label="达标时间">
							<template slot-scope="scope">
								{{ scope.row.achieve_time || '--' }}
							</template>
						</el-table-column>
						<el-table-column prop="inspire" label="红包金额(元)"></el-table-column>
						<el-table-column label="发放情况">
							<template slot-scope="scope">
								<span v-if="scope.row.is_grant == 0">未确认</span>
								<span v-else-if="scope.row.is_grant == 2">已确认</span>
								<el-button type="text" v-else-if="scope.row.is_grant == 1">确认发放</el-button>
							</template>
						</el-table-column>
						<el-table-column label="发放时间">
							<template slot-scope="scope">
								{{ scope.row.grant_time || '--' }}
							</template>
						</el-table-column>
					</el-table>
				</div>
				<p style="margin: 15px 0;">季度目标</p>
				<div class="table">
					<el-table
						v-loading="loading"
						:data="quarterData"
						style="width: 100%"
						:header-cell-style="{
							color: '#333',
							fontSize: '14px',
							backgroundColor: '#F6F7FB',
							fontWeight: 'normal'
						}"
					>
						<el-table-column prop="name" label="季度"></el-table-column>
						<el-table-column prop="target" label="季度目标"></el-table-column>
						<el-table-column prop="finish" label="完成进度">
							<template slot-scope="scope">
								{{ parseInt((scope.row.target * scope.row.finish) / 100) }} ({{ scope.row.finish }}%)
							</template>
						</el-table-column>
						<el-table-column label="是否完成">
							<template slot-scope="scope">
								{{ scope.row.is_complete == 1 ? '是' : '否' }}
							</template>
						</el-table-column>
						<el-table-column label="达标时间">
							<template slot-scope="scope">
								{{ scope.row.achieve_time || '--' }}
							</template>
						</el-table-column>
						<el-table-column prop="inspire" label="红包金额(元)"></el-table-column>
						<el-table-column label="发放情况">
							<template slot-scope="scope">
								<span v-if="scope.row.is_grant == 0">未确认</span>
								<span v-else-if="scope.row.is_grant == 2">已确认</span>
								<el-button type="text" v-else-if="scope.row.is_grant == 1">确认发放</el-button>
							</template>
						</el-table-column>
						<el-table-column label="发放时间">
							<template slot-scope="scope">
								{{ scope.row.grant_time || '--' }}
							</template>
						</el-table-column>
					</el-table>
				</div>
				<p style="margin: 15px 0;">月度目标</p>
				<div class="table">
					<el-table
						v-loading="loading"
						:data="monthData"
						style="width: 100%"
						:header-cell-style="{
							color: '#333',
							fontSize: '14px',
							backgroundColor: '#F6F7FB',
							fontWeight: 'normal'
						}"
					>
						<el-table-column prop="name" label="月份"></el-table-column>
						<el-table-column prop="target" label="月度目标"></el-table-column>
						<el-table-column prop="finish" label="完成进度">
							<template slot-scope="scope">
								{{ parseInt((scope.row.target * scope.row.finish) / 100) }} ({{ scope.row.finish }}%)
							</template>
						</el-table-column>
						<el-table-column label="是否完成">
							<template slot-scope="scope">
								{{ scope.row.is_complete == 1 ? '是' : '否' }}
							</template>
						</el-table-column>
						<el-table-column label="达标时间">
							<template slot-scope="scope">
								{{ scope.row.achieve_time || '--' }}
							</template>
						</el-table-column>
						<el-table-column prop="inspire" label="红包金额(元)"></el-table-column>
						<el-table-column label="发放情况">
							<template slot-scope="scope">
								<span v-if="scope.row.is_grant == 0">未确认</span>
								<span v-else-if="scope.row.is_grant == 2">已确认</span>
								<el-button type="text" v-else-if="scope.row.is_grant == 1">确认发放</el-button>
							</template>
						</el-table-column>
						<el-table-column label="发放时间">
							<template slot-scope="scope">
								{{ scope.row.grant_time || '--' }}
							</template>
						</el-table-column>
					</el-table>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import { reqGetDevelopCustomInfo } from '@/api/index';
export default {
	props: ['visible', 'yearList', 'row'],
	data() {
		return {
			date_year: '',
			year: '',
			staff_user_id: '',
			info: {},
			loading: false,
			yearData: [],
			quarterData: [],
			monthData: []
		};
	},
	watch: {
		row(val) {
			this.year = val.date_year;
			this.staff_user_id = val.staff_id;
		},
		visible(val) {
			if (val) {
				this.getDetail();
			}
		}
	},
	methods: {
		getDetail() {
			this.loading = true;
			reqGetDevelopCustomInfo({
				date_year: this.year,
				type: 2,
				staff_user_id: this.staff_user_id,
				staff_target: 1
			}).then(res => {
				if (res.data.length != 0) {
					this.info = res.data;
					this.getTableData(res.data);
				} else {
					this.info = {};
					this.tableData = [];
					this.yearData = [];
					this.quarterData = [];
					this.monthData = [];
				}
				this.loading = false;
			});
		},
		handleClose() {
			this.$emit('detailClose');
		},
		getTableData(option) {
			let year = [],
				quarter = [],
				month = [];
			for (let key in option.target) {
				if (key == 'year_target') {
					let obj = option.target[key];
					obj.name = this.year + '年';
					obj.target = option[key];
					obj.finish = option[`fini_${key}`];
					year.push(obj);
				} else if (key.includes('quarter')) {
					let obj = option.target[key];
					obj.target = option[key];
					obj.finish = option[`fini_${key}`];
					if (key.includes('one')) {
						obj.name = '第一季度';
						obj.index = 1;
					} else if (key.includes('two')) {
						obj.name = '第二季度';
						obj.index = 2;
					} else if (key.includes('three')) {
						obj.name = '第三季度';
						obj.index = 3;
					} else if (key.includes('four')) {
						obj.name = '第四季度';
						obj.index = 4;
					}
					quarter.push(obj);
				} else if (key.includes('month')) {
					let obj = option.target[key];
					obj.target = option[key];
					obj.finish = option[`fini_${key}`];
					if (key.includes('one')) {
						obj.name = this.year + '/01';
						obj.index = 1;
					} else if (key.includes('two')) {
						obj.name = this.year + '/02';
						obj.index = 2;
					} else if (key.includes('three')) {
						obj.name = this.year + '/03';
						obj.index = 3;
					} else if (key.includes('four')) {
						obj.name = this.year + '/04';
						obj.index = 4;
					} else if (key.includes('five')) {
						obj.name = this.year + '/05';
						obj.index = 5;
					} else if (key.includes('six')) {
						obj.name = this.year + '/06';
						obj.index = 6;
					} else if (key.includes('seven')) {
						obj.name = this.year + '/07';
						obj.index = 7;
					} else if (key.includes('eight')) {
						obj.name = this.year + '/08';
						obj.index = 8;
					} else if (key.includes('nine')) {
						obj.name = this.year + '/09';
						obj.index = 9;
					} else if (key.includes('ten')) {
						obj.name = this.year + '/10';
						obj.index = 10;
					} else if (key.includes('eleven')) {
						obj.name = this.year + '/11';
						obj.index = 11;
					} else if (key.includes('twelve')) {
						obj.name = this.year + '/12';
						obj.index = 12;
					}
					month.push(obj);
				}
			}
			quarter.sort(function(a, b) {
				return a.index - b.index;
			});
			month.sort(function(a, b) {
				return a.index - b.index;
			});
			this.yearData = year;
			this.quarterData = quarter;
			this.monthData = month;
		}
	}
};
</script>

<style lang="less" scoped>
.detail {
	.main {
		display: flex;
		align-items: flex-start;

		.year {
			display: flex;
			flex-direction: column;
			align-items: center;
			flex: 2;
			background: #ffffff;
			border-radius: 6px;
			border: 1px solid #b9c5e9;
			margin-right: 15px;
			padding: 10px;

			> div + div {
				margin-top: 15px;
			}

			.head {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
		}

		.quarter {
			flex: 5;
			background: #ffffff;
			border-radius: 6px;
			border: 1px solid #b9c5e9;
			padding: 10px;

			.wrap {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: space-between;

				> div {
					display: flex;
					flex-direction: column;
					align-items: center;

					> div {
						margin-top: 15px;
					}
				}
			}
		}
	}
}
</style>
