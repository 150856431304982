<template>
	<div class="company" v-loading="loading">
		<div class="btns">
			<el-select size="small" style="margin-right: 10px;" @change="getData()" v-model="year" placeholder="选择年份进行查询">
				<el-option v-for="(item, key) in yearList" :key="key" :label="item" :value="item"></el-option>
			</el-select>
		</div>
		<div class="scroll">
			<div class="finish">
				<div class="year" v-if="info.date_year">
					<p>年度完成情况</p>
					<div class="progress">
						<el-progress
							type="circle"
							:width="150"
							color="#6881EC"
							:stroke-width="18"
							:format="
								() => {
									return parseInt((info.year_target / 100) * info.fini_year_target) + '/' + info.year_target;
								}
							"
							:percentage="Number(info.fini_year_target) >= 100 ? 100 : Number(info.fini_year_target)"
						></el-progress>
						<div class="text">
							<p>年度目标完成度</p>
							<p>{{ info.fini_year_target }}%</p>
						</div>
					</div>
				</div>
				<div class="quarter" v-if="info.date_year">
					<p>季度完成情况</p>
					<div class="wrap">
						<div class="progress">
							<el-progress
								type="circle"
								:width="150"
								color="#67C23A"
								:stroke-width="18"
								:format="
									() => {
										return parseInt((info.one_quarter / 100) * info.fini_one_quarter) + '/' + info.one_quarter;
									}
								"
								:percentage="Number(info.fini_one_quarter) >= 100 ? 100 : Number(info.fini_one_quarter)"
							></el-progress>
							<div class="text">
								<p>第一季度</p>
								<p>{{ info.fini_one_quarter }}%</p>
							</div>
						</div>
						<span class="line"></span>
						<div class="progress">
							<el-progress
								type="circle"
								:width="150"
								color="#F56C6C"
								:stroke-width="18"
								:format="
									() => {
										return parseInt((info.two_quarter / 100) * info.fini_two_quarter) + '/' + info.two_quarter;
									}
								"
								:percentage="Number(info.fini_two_quarter) >= 100 ? 100 : Number(info.fini_two_quarter)"
							></el-progress>
							<div class="text">
								<p>第二季度</p>
								<p>{{ info.fini_two_quarter }}%</p>
							</div>
						</div>
						<span class="line"></span>
						<div class="progress">
							<el-progress
								type="circle"
								:width="150"
								color="#6881EC"
								:stroke-width="18"
								:format="
									() => {
										return parseInt((info.three_quarter / 100) * info.fini_three_quarter) + '/' + info.three_quarter;
									}
								"
								:percentage="Number(info.fini_three_quarter) >= 100 ? 100 : Number(info.fini_three_quarter)"
							></el-progress>
							<div class="text">
								<p>第三季度</p>
								<p>{{ info.fini_three_quarter }}%</p>
							</div>
						</div>
						<span class="line"></span>
						<div class="progress">
							<el-progress
								type="circle"
								:width="150"
								color="#E6A23C"
								:stroke-width="18"
								:format="
									() => {
										return parseInt((info.four_quarter / 100) * info.fini_four_quarter) + '/' + info.four_quarter;
									}
								"
								:percentage="Number(info.fini_four_quarter)"
							></el-progress>
							<div class="text">
								<p>第四季度</p>
								<p>{{ info.fini_four_quarter }}%</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="month">
				<p>月度完成情况</p>
				<div class="main"><div id="bar"></div></div>
			</div>
			<div class="head">
				<span>企业计划</span>
				<el-button
					size="small"
					class="btn-blue"
					@click="
						() => {
							type = '';
							visible = true;
						}
					"
				>
					新建企业计划
				</el-button>
			</div>
			<div class="table">
				<el-table
					v-loading="loading"
					element-loading-text="加载中..."
					element-loading-spinner="el-icon-loading"
					:data="tableData"
					style="width: 100%"
					:header-cell-style="{
						color: '#333',
						fontSize: '14px',
						backgroundColor: '#F6F7FB',
						fontWeight: 'normal',
						textAlign: 'center'
					}"
					:cell-style="{
						textAlign: 'center'
					}"
				>
					<el-table-column prop="date_year" label="所属年度"></el-table-column>
					<el-table-column prop="year_target" label="年度计划"></el-table-column>
					<el-table-column label="季度计划">
						<el-table-column prop="one_quarter" label="第一季度"></el-table-column>
						<el-table-column prop="two_quarter" label="第二季度"></el-table-column>
						<el-table-column prop="three_quarter" label="第三季度"></el-table-column>
						<el-table-column prop="four_quarter" label="第四季度"></el-table-column>
					</el-table-column>
					<el-table-column label="月度计划">
						<el-table-column prop="one_month" label="1月"></el-table-column>
						<el-table-column prop="two_month" label="2月"></el-table-column>
						<el-table-column prop="three_month" label="3月"></el-table-column>
						<el-table-column prop="four_month" label="4月"></el-table-column>
						<el-table-column prop="five_month" label="5月"></el-table-column>
						<el-table-column prop="six_month" label="6月"></el-table-column>
						<el-table-column prop="seven_month" label="7月"></el-table-column>
						<el-table-column prop="eight_month" label="8月"></el-table-column>
						<el-table-column prop="nine_month" label="9月"></el-table-column>
						<el-table-column prop="ten_month" label="10月"></el-table-column>
						<el-table-column prop="eleven_month" label="11月"></el-table-column>
						<el-table-column prop="twelve_month" label="12月"></el-table-column>
					</el-table-column>
					<el-table-column label="操作">
						<template slot-scope="scope">
							<el-button type="text" @click="handleEdit(scope.row)">编辑</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="pagination" v-if="total !== 0">
				<el-pagination
					@size-change="
						pageSize => {
							limit = pageSize;
							page = 1;
							getList();
						}
					"
					@current-change="
						currentPage => {
							page = currentPage;
							getList();
						}
					"
					:current-page="page"
					:page-sizes="[10, 20, 40, 80]"
					:page-size="limit"
					layout="total, sizes, prev, pager, next, jumper"
					:total="total"
				></el-pagination>
			</div>
		</div>
		<!-- 新增编辑企业计划 -->
		<Edit :visible="visible" @close="handleClose" :row="row" :typeIndex="1" :type="type" @update="handleUpdate" />
	</div>
</template>

<script>
import { reqGetCompanyPlanList, reqGetDevelopCustomInfo } from '@/api/index';
import Edit from './Edit.vue';
export default {
	props: ['yearList', 'active'],
	components: {
		Edit
	},
	data() {
		return {
			year: '',
			loading: false,
			tableData: [],
			limit: 10,
			page: 1,
			total: 0,
			visible: false,
			row: {},
			type: '',
			info: {},
			chart: null
		};
	},
	watch: {
		yearList(val) {
			if (val.length >= 1) {
				val.some(item => {
					return item == new Date().getFullYear();
				})
					? (this.year = new Date().getFullYear())
					: (this.year = val[0]);
			}
		},
		active(val) {
			if (val == 1 && this.chart) {
				this.chart.resize();
			}
		}
	},
	mounted() {
		this.$nextTick(() => {
			this.initChart();
		});
		this.year = new Date().getFullYear();
		this.getData();
	},
	beforeDestroy() {
		if (!this.chart) {
			return;
		}
		this.chart.dispose();
		this.chart = null;
	},
	methods: {
		initChart() {
			this.chart = this.$echarts.init(document.getElementById('bar'));
			window.addEventListener('resize', () => {
				this.chart ? this.chart.resize() : '';
			});
		},
		getData() {
			this.loading = true;
			Promise.all([
				new Promise(resolve => {
					this.getList(resolve);
				}),
				new Promise(resolve => {
					this.getInfo(resolve);
				})
			]).then(res => {
				this.loading = false;
			});
		},
		getList(resolve = false) {
			reqGetCompanyPlanList({
				type: 1,
				page: this.page,
				limit: this.limit
			}).then(res => {
				this.tableData = res.data;
				this.total = res.count || 0;
				resolve ? resolve() : '';
			});
		},
		// 获取计划完成情况
		getInfo(resolve = false) {
			reqGetDevelopCustomInfo({
				date_year: this.year,
				type: 1
			}).then(res => {
				this.info = res.data;
				let data_name = [],
					data_value = [];
				data_value = [
					(this.info.fini_one_month * this.info.one_month) / 100,
					(this.info.fini_two_month * this.info.two_month) / 100,
					(this.info.fini_three_month * this.info.three_month) / 100,
					(this.info.fini_four_month * this.info.four_month) / 100,
					(this.info.fini_five_month * this.info.five_month) / 100,
					(this.info.fini_six_month * this.info.six_month) / 100,
					(this.info.fini_seven_month * this.info.seven_month) / 100,
					(this.info.fini_eight_month * this.info.eight_month) / 100,
					(this.info.fini_nine_month * this.info.nine_month) / 100,
					(this.info.fini_ten_month * this.info.ten_month) / 100,
					(this.info.fini_eleven_month * this.info.eleven_month) / 100,
					(this.info.fini_twelve_month * this.info.twelve_month) / 100
				];
				data_name = [
					`一月完成度\n${this.info.fini_one_month || 0}%`,
					`二月完成度\n${this.info.fini_two_month || 0}%`,
					`三月完成度\n${this.info.fini_three_month || 0}%`,
					`四月完成度\n${this.info.fini_four_month || 0}%`,
					`五月完成度\n${this.info.fini_five_month || 0}%`,
					`六月完成度\n${this.info.fini_six_month || 0}%`,
					`七月完成度\n${this.info.fini_seven_month || 0}%`,
					`八月完成度\n${this.info.fini_eight_month || 0}%`,
					`九月完成度\n${this.info.fini_nine_month || 0}%`,
					`十月完成度\n${this.info.fini_ten_month || 0}%`,
					`十一月完成度\n${this.info.fini_eleven_month || 0}%`,
					`十二月完成度\n${this.info.fini_twelve_month || 0}%`
				];
				this.setOption(data_name, data_value);
				resolve ? resolve() : '';
			});
		},
		// 进度条文字控制
		format(percentage) {
			let all = 40;
			return (percentage / 100) * all + '/' + all;
		},
		setOption(name = [], data = []) {
			this.chart.setOption({
				xAxis: {
					type: 'category',
					axisLabel: {
						color: '#333', //坐标的字体颜色
						lineHeight: 17
					},
					axisLine: {
						lineStyle: {
							color: '#E5EBFB'
						}
					},
					axisTick: {
						show: false
					},
					data: name
				},
				grid: {
					top: 20,
					left: 0,
					bottom: 45,
					right: 0
				},
				yAxis: {
					type: 'value',
					splitLine: {
						show: false
					}
				},
				series: [
					{
						data,
						type: 'bar',
						showBackground: true,
						barWidth: 30,
						label: {
							show: true,
							position: 'top',
							lineHeight: 25,
							formatter: obj => {
								let target = 0;
								if (obj.name.split('度')[1].split('%')[0] == 0) {
									return 0;
								} else {
									target = (obj.data / obj.name.split('度')[1].split('%')[0]) * 100;
									return Math.round(obj.data) + '/' + Math.round(target);
								}
							}
						},
						itemStyle: {
							borderRadius: [5, 5, 0, 0]
						},
						backgroundStyle: {
							color: 'rgba(180, 180, 180, 0.2)',
							borderRadius: [5, 5, 0, 0]
						}
					}
				]
			});
		},
		// 关闭新增编辑弹窗
		handleClose() {
			this.visible = false;
		},
		// 更新列表数据
		handleUpdate() {
			this.getData();
			this.$bus.$emit('getYearList');
		},
		handleEdit(row) {
			this.row = {
				date_year: row.date_year,
				year_target: row.year_target,
				one_quarter: row.one_quarter,
				two_quarter: row.two_quarter,
				three_quarter: row.three_quarter,
				four_quarter: row.four_quarter,
				one_month: row.one_month,
				two_month: row.two_month,
				three_month: row.three_month,
				four_month: row.four_month,
				five_month: row.five_month,
				six_month: row.six_month,
				seven_month: row.seven_month,
				eight_month: row.eight_month,
				nine_month: row.nine_month,
				ten_month: row.ten_month,
				eleven_month: row.eleven_month,
				twelve_month: row.twelve_month,
				id: row.id
			};
			this.type = 'edit';
			this.visible = true;
		}
	}
};
</script>

<style lang="less" scoped>
.company {
	width: 100%;
	position: relative;
	overflow: hidden;
	font-size: 14px;
	color: #333;

	> div + div {
		margin-top: 15px;
	}

	.scroll {
		width: 100%;
		height: calc(100vh - 280px);
		overflow-y: auto;
		overflow-x: hidden;

		> div + div {
			margin-top: 15px;
		}

		.finish {
			display: flex;
			align-items: center;

			.year {
				width: 330px;
				height: 240px;
				margin-right: 15px;
				background: #ffffff;
				border-radius: 6px;
				border: 1px solid #b9c5e9;
				padding: 10px;

				.progress {
					width: calc(100% - 20px);
					height: calc(100% - 35px);
					margin-top: 15px;
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 0 10px;

					.el-progress {
						margin-right: 15px;
					}

					.text {
						display: flex;
						flex-direction: column;
						justify-content: space-between;

						> p + p {
							margin-top: 15px;
						}
					}
				}
			}

			.quarter {
				flex-grow: 1;
				height: 240px;
				margin-right: 15px;
				background: #ffffff;
				border-radius: 6px;
				border: 1px solid #b9c5e9;
				padding: 10px;

				.wrap {
					display: flex;
					align-items: center;
					justify-content: space-between;
					height: 100%;

					.progress {
						width: calc(100% - 20px);
						height: calc(100% - 35px);
						display: flex;
						align-items: center;
						justify-content: center;
						padding: 0 10px;

						.el-progress {
							margin-right: 15px;
						}

						.text {
							display: flex;
							flex-direction: column;
							justify-content: space-between;

							> p + p {
								margin-top: 15px;
							}
						}
					}

					.line {
						width: 1px;
						height: 100px;
						background: #e5ebfb;
					}
				}
			}
		}

		.month {
			width: calc(100% - 37px);
			height: 220px;
			background: #ffffff;
			border-radius: 6px;
			border: 1px solid #b9c5e9;
			padding: 10px;

			.main {
				width: 100%;
				height: calc(100% - 35px);
				margin-top: 15px;

				#bar {
					width: 100%;
					height: 100%;
				}
			}
		}

		.head {
			width: calc(100% - 15px);
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.table,
		.pagination {
			width: calc(100% - 15px);
		}
	}
}
</style>
