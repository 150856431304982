<template>
	<div class="edit">
		<el-dialog :close-on-click-modal='false'
			:title="type=='edit'?`编辑${typeIndex==1?'企业':'成员'}计划`:`新增${typeIndex==1?'企业':'成员'}计划`"
			:visible.sync="visible" width="570px" :before-close="handleClose">
			<div class="scroll" style="min-height: 500px;" v-loading="loading">
				<el-form v-if="!loading" label-position="left" label-width="80px" :model="form" ref="form">
					<el-form-item label="所属年度" prop="date_year"
						:rules="[{ required: true, message: '请选择所属年份', trigger: 'change' }]">
						<el-select :disabled="type=='edit'" size="small" style="margin-right: 10px;"
							v-model="form.date_year" placeholder="请选择所属年份">
							<el-option v-for="(item,key) in yearList" :key="key" :label="item" :value="item">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item v-if="typeIndex==2" label="选择成员" prop="staff_user_id"
						:rules="[{ required: true, message: '请选择成员', trigger: 'change' }]">
						<el-select :loading='loading' :disabled="type=='edit'" multiple size="small"
							style="margin-right: 10px;" v-model="form.staff_user_id" placeholder="请选择成员">
							<el-option v-for="(item,key) in staffList" :key="item.user_id" :label="item.name"
								:value="item.user_id">
							</el-option>
						</el-select>
					</el-form-item>
					<p>年度计划</p>
					<div class="flex">
						<el-form-item label="年度目标" prop="year_target"
							:rules="[{ required: true, message: '请输入年度目标', trigger: 'blur' }]">
							<el-input maxlength="5" size="small" v-model.number="form.year_target"
								@input="(value)=>{form.year_target = $isnumber(value)}"></el-input>
						</el-form-item>
						<el-form-item v-if="typeIndex==2" label="激励金额">
							<el-input maxlength="5" size="small" v-model.number="form.target.year_target.inspire"
								@input="(value)=>{form.target.year_target.inspire = $isnumber(value)}"></el-input>
						</el-form-item>
					</div>
					<p>季度计划</p>
					<div class="flex">
						<el-form-item label="第一季度">
							<el-input maxlength="5" size="small" v-model.number="form.one_quarter"
								@input="(value)=>{form.one_quarter = $isnumber(value)}"></el-input>
						</el-form-item>
						<el-form-item v-if="typeIndex==2" label="激励金额">
							<el-input maxlength="5" size="small" v-model.number="form.target.one_quarter.inspire"
								@input="(value)=>{form.target.one_quarter.inspire = $isnumber(value)}"></el-input>
						</el-form-item>
					</div>
					<div class="flex">
						<el-form-item label="第二季度">
							<el-input maxlength="5" size="small" v-model.number="form.two_quarter"
								@input="(value)=>{form.two_quarter = $isnumber(value)}"></el-input>
						</el-form-item>
						<el-form-item v-if="typeIndex==2" label="激励金额">
							<el-input maxlength="5" size="small" v-model.number="form.target.two_quarter.inspire"
								@input="(value)=>{form.target.two_quarter.inspire = $isnumber(value)}"></el-input>
						</el-form-item>
					</div>
					<div class="flex">
						<el-form-item label="第三季度">
							<el-input maxlength="5" size="small" v-model.number="form.three_quarter"
								@input="(value)=>{form.three_quarter = $isnumber(value)}"></el-input>
						</el-form-item>
						<el-form-item v-if="typeIndex==2" label="激励金额">
							<el-input maxlength="5" size="small" v-model.number="form.target.three_quarter.inspire"
								@input="(value)=>{form.target.three_quarter.inspire = $isnumber(value)}"></el-input>
						</el-form-item>
					</div>
					<div class="flex">
						<el-form-item label="第四季度">
							<el-input maxlength="5" size="small" v-model.number="form.four_quarter"
								@input="(value)=>{form.four_quarter = $isnumber(value)}"></el-input>
						</el-form-item>
						<el-form-item v-if="typeIndex==2" label="激励金额">
							<el-input maxlength="5" size="small" v-model.number="form.target.four_quarter.inspire"
								@input="(value)=>{form.target.four_quarter.inspire = $isnumber(value)}"></el-input>
						</el-form-item>
					</div>
					<p>月度计划</p>
					<div class="flex-month">
						<el-form-item label="一月">
							<el-input maxlength="5" size="small" v-model="form.one_month"
								@input="(value)=>{form.one_month = $isnumber(value)}"></el-input>
						</el-form-item>
						<el-form-item v-if="typeIndex==2" label="激励金额">
							<el-input maxlength="5" size="small" v-model="form.target.one_month.inspire"
								@input="(value)=>{form.target.one_month.inspire = $isnumber(value)}"></el-input>
						</el-form-item>
						<el-form-item label="二月">
							<el-input maxlength="5" size="small" v-model="form.two_month"
								@input="(value)=>{form.two_month = $isnumber(value)}"></el-input>
						</el-form-item>
						<el-form-item v-if="typeIndex==2" label="激励金额">
							<el-input maxlength="5" size="small" v-model="form.target.two_month.inspire"
								@input="(value)=>{form.target.two_month.inspire = $isnumber(value)}"></el-input>
						</el-form-item>
						<el-form-item label="三月">
							<el-input maxlength="5" size="small" v-model="form.three_month"
								@input="(value)=>{form.three_month = $isnumber(value)}"></el-input>
						</el-form-item>
						<el-form-item v-if="typeIndex==2" label="激励金额">
							<el-input maxlength="5" size="small" v-model="form.target.three_month.inspire"
								@input="(value)=>{form.target.three_month.inspire = $isnumber(value)}"></el-input>
						</el-form-item>
						<el-form-item label="四月">
							<el-input maxlength="5" size="small" v-model="form.four_month"
								@input="(value)=>{form.four_month = $isnumber(value)}"></el-input>
						</el-form-item>
						<el-form-item v-if="typeIndex==2" label="激励金额">
							<el-input maxlength="5" size="small" v-model="form.target.four_month.inspire"
								@input="(value)=>{form.target.four_month.inspire = $isnumber(value)}"></el-input>
						</el-form-item>
						<el-form-item label="五月">
							<el-input maxlength="5" size="small" v-model="form.five_month"
								@input="(value)=>{form.five_month = $isnumber(value)}"></el-input>
						</el-form-item>
						<el-form-item v-if="typeIndex==2" label="激励金额">
							<el-input maxlength="5" size="small" v-model="form.target.five_month.inspire"
								@input="(value)=>{form.target.five_month.inspire = $isnumber(value)}"></el-input>
						</el-form-item>
						<el-form-item label="六月">
							<el-input maxlength="5" size="small" v-model="form.six_month"
								@input="(value)=>{form.six_month = $isnumber(value)}"></el-input>
						</el-form-item>
						<el-form-item v-if="typeIndex==2" label="激励金额">
							<el-input maxlength="5" size="small" v-model="form.target.six_month.inspire"
								@input="(value)=>{form.target.six_month.inspire = $isnumber(value)}"></el-input>
						</el-form-item>
						<el-form-item label="七月">
							<el-input maxlength="5" size="small" v-model="form.seven_month"
								@input="(value)=>{form.seven_month = $isnumber(value)}"></el-input>
						</el-form-item>
						<el-form-item v-if="typeIndex==2" label="激励金额">
							<el-input maxlength="5" size="small" v-model="form.target.seven_month.inspire"
								@input="(value)=>{form.target.seven_month.inspire = $isnumber(value)}"></el-input>
						</el-form-item>
						<el-form-item label="八月">
							<el-input maxlength="5" size="small" v-model="form.eight_month"
								@input="(value)=>{form.eight_month = $isnumber(value)}"></el-input>
						</el-form-item>
						<el-form-item v-if="typeIndex==2" label="激励金额">
							<el-input maxlength="5" size="small" v-model="form.target.eight_month.inspire"
								@input="(value)=>{form.target.eight_month.inspire = $isnumber(value)}"></el-input>
						</el-form-item>
						<el-form-item label="九月">
							<el-input maxlength="5" size="small" v-model="form.nine_month"
								@input="(value)=>{form.nine_month = $isnumber(value)}"></el-input>
						</el-form-item>
						<el-form-item v-if="typeIndex==2" label="激励金额">
							<el-input maxlength="5" size="small" v-model="form.target.nine_month.inspire"
								@input="(value)=>{form.target.nine_month.inspire = $isnumber(value)}"></el-input>
						</el-form-item>
						<el-form-item label="十月">
							<el-input maxlength="5" size="small" v-model="form.ten_month"
								@input="(value)=>{form.ten_month = $isnumber(value)}"></el-input>
						</el-form-item>
						<el-form-item v-if="typeIndex==2" label="激励金额">
							<el-input maxlength="5" size="small" v-model="form.target.ten_month.inspire"
								@input="(value)=>{form.target.ten_month.inspire = $isnumber(value)}"></el-input>
						</el-form-item>
						<el-form-item label="十一月">
							<el-input maxlength="5" size="small" v-model="form.eleven_month"
								@input="(value)=>{form.eleven_month = $isnumber(value)}"></el-input>
						</el-form-item>
						<el-form-item v-if="typeIndex==2" label="激励金额">
							<el-input maxlength="5" size="small" v-model="form.target.eleven_month.inspire"
								@input="(value)=>{form.target.eleven_month.inspire = $isnumber(value)}"></el-input>
						</el-form-item>
						<el-form-item label="十二月">
							<el-input maxlength="5" size="small" v-model="form.twelve_month"
								@input="(value)=>{form.twelve_month = $isnumber(value)}"></el-input>
						</el-form-item>
						<el-form-item v-if="typeIndex==2" label="激励金额">
							<el-input maxlength="5" size="small" v-model="form.target.twelve_month.inspire"
								@input="(value)=>{form.target.twelve_month.inspire = $isnumber(value)}"></el-input>
						</el-form-item>
					</div>
					<p v-if="typeIndex==2">提醒设置</p>
					<el-form-item v-if="typeIndex==2" label="成员目标推送" label-width="150px" required>
						<el-checkbox v-model="form.is_target_push" :true-label='1' :false-label='0'></el-checkbox>
						<el-tooltip class="item" effect="dark" content="当目标设置完成时,成员将会收到此激励目标以及每日完成情况的消息推送"
							placement="top-start">
							<i style="vertical-align: middle;font-size: 18px;margin-left: 10px;"
								class="ri-question-line"></i>
						</el-tooltip>
					</el-form-item>
					<el-form-item v-if="typeIndex==2" label="达标结果推送" label-width="150px" prop="complete_push_staff"
						:rules="[{ required: true, message: '请选择成员', trigger: 'change' }]">
						<el-select :loading='loading' multiple size="small" style="margin-right: 10px;width: 300px;"
							v-model="form.complete_push_staff" placeholder="请选择成员">
							<el-option v-for="(item,key) in staffList" :key="item.user_id" :label="item.name"
								:value="item.user_id">
							</el-option>
						</el-select>
						<el-tooltip class="item" effect="dark" content="当目标设置完成时,该成员将会收到成员目标完成消息推送"
							placement="top-start">
							<i style="vertical-align: middle;font-size: 18px;" class="ri-question-line"></i>
						</el-tooltip>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button size="small" class="btn-white" @click="handleClose()">取 消</el-button>
				<el-button size="small" class="btn-blue" @click="handleSubmit('form')">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		reqAddDevelopCustom,
		reqAddStaffDevelopCustom,
		reqGetSoloStaff
	} from '@/api/index';
	export default {
		props: ['visible', 'row', 'type', 'typeIndex'],
		data() {
			return {
				form: {
					date_year: '',
					staff_user_id: [],
					is_target_push: 1,
					complete_push_staff: [],
					year_target: '',
					target: {
						year_target: {
							inspire: ''
						},
						one_quarter: {
							inspire: ''
						},
						two_quarter: {
							inspire: ''
						},
						three_quarter: {
							inspire: ''
						},
						four_quarter: {
							inspire: ''
						},
						one_month: {
							inspire: ''
						},
						two_month: {
							inspire: ''
						},
						three_month: {
							inspire: ''
						},
						four_month: {
							inspire: ''
						},
						five_month: {
							inspire: ''
						},
						six_month: {
							inspire: ''
						},
						seven_month: {
							inspire: ''
						},
						eight_month: {
							inspire: ''
						},
						nine_month: {
							inspire: ''
						},
						ten_month: {
							inspire: ''
						},
						eleven_month: {
							inspire: ''
						},
						twelve_month: {
							inspire: ''
						},
					}
				},
				yearList: [],
				staffList: [],
				loading: false
			}
		},
		watch: {
			visible(val) {
				if (val) {
					this.getStaffList();
				}
				this.yearList = [];
				for (let i = 0; i < 10; i++) {
					this.yearList.push(Number(new Date().getFullYear() + i));
				}
			},
			row(val) {
				this.$nextTick(() => {
					this.form = JSON.parse(JSON.stringify(val));
					let target = this.form.target;
					if (target.length == 0) {
						this.form.target = {
							year_target: {
								date_name: "year_target",
								inspire: ''
							},
							one_quarter: {
								date_name: "one_quarter",
								inspire: ''
							},
							two_quarter: {
								date_name: "two_quarter",
								inspire: ''
							},
							three_quarter: {
								date_name: "three_quarter",
								inspire: ''
							},
							four_quarter: {
								date_name: "four_quarter",
								inspire: ''
							},
							one_month: {
								date_name: "one_month",
								inspire: ''
							},
							two_month: {
								date_name: "two_month",
								inspire: ''
							},
							three_month: {
								date_name: "three_month",
								inspire: ''
							},
							four_month: {
								date_name: "four_month",
								inspire: ''
							},
							five_month: {
								date_name: "five_month",
								inspire: ''
							},
							six_month: {
								date_name: "six_month",
								inspire: ''
							},
							seven_month: {
								date_name: "seven_month",
								inspire: ''
							},
							eight_month: {
								date_name: "eight_month",
								inspire: ''
							},
							nine_month: {
								date_name: "nine_month",
								inspire: ''
							},
							ten_month: {
								date_name: "ten_month",
								inspire: ''
							},
							eleven_month: {
								date_name: "eleven_month",
								inspire: ''
							},
							twelve_month: {
								date_name: "twelve_month",
								inspire: ''
							},
						}
					} else {
						this.form.target = {
							year_target: {
								date_name: "year_target",
								inspire: target.year_target.inspire || ''
							},
							one_quarter: {
								date_name: "one_quarter",
								inspire: target.one_quarter.inspire || ''
							},
							two_quarter: {
								date_name: "two_quarter",
								inspire: target.two_quarter.inspire || ''
							},
							three_quarter: {
								date_name: "three_quarter",
								inspire: target.three_quarter.inspire || ''
							},
							four_quarter: {
								date_name: "four_quarter",
								inspire: target.four_quarter.inspire || ''
							},
							one_month: {
								date_name: "one_month",
								inspire: target.one_month.inspire || ''
							},
							two_month: {
								date_name: "two_month",
								inspire: target.two_month.inspire || ''
							},
							three_month: {
								date_name: "three_month",
								inspire: target.three_month.inspire || ''
							},
							four_month: {
								date_name: "four_month",
								inspire: target.four_month.inspire || ''
							},
							five_month: {
								date_name: "five_month",
								inspire: target.five_month.inspire || ''
							},
							six_month: {
								date_name: "six_month",
								inspire: target.six_month.inspire || ''
							},
							seven_month: {
								date_name: "seven_month",
								inspire: target.seven_month.inspire || ''
							},
							eight_month: {
								date_name: "eight_month",
								inspire: target.eight_month.inspire || ''
							},
							nine_month: {
								date_name: "nine_month",
								inspire: target.nine_month.inspire || ''
							},
							ten_month: {
								date_name: "ten_month",
								inspire: target.ten_month.inspire || ''
							},
							eleven_month: {
								date_name: "eleven_month",
								inspire: target.eleven_month.inspire || ''
							},
							twelve_month: {
								date_name: "twelve_month",
								inspire: target.twelve_month.inspire || ''
							},
						}
					}
				})
			}
		},
		methods: {
			getStaffList() {
				this.loading = true;
				reqGetSoloStaff({
					page: 1,
					limit: 10000
				}).then(res => {
					this.loading = false;
					this.staffList = res.data;
				})
			},
			handleClose() {
				Object.assign(this.$data, this.$options.data());
				this.$refs.form.clearValidate();
				this.$emit('close');
			},
			handleSubmit(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.form.type = this.typeIndex;
						let params = JSON.parse(JSON.stringify(this.form));
						if (this.typeIndex == 1) {
							reqAddDevelopCustom(params).then(res => {
								this.$message.success(res.msg);
								this.handleClose();
								this.$emit('update');
							})
						} else {
							params.staff_user_id = params.staff_user_id.join(',');
							params.complete_push_staff = params.complete_push_staff.join(',');
							reqAddStaffDevelopCustom(params).then(res => {
								this.$message.success(res.msg);
								this.handleClose();
								this.$emit('update');
							})
						}
					} else {
						return false;
					}
				});
			}
		}
	}
</script>

<style lang='less' scoped>
	.edit {
		.el-dialog {
			p {
				color: #6881EC;
			}

			.el-input,
			.el-select {
				width: 100%;
			}

			.flex {
				display: flex;
				align-items: center;
				justify-content: space-between;

				.el-form-item {
					flex: 1;
				}

				.el-form-item+.el-form-item {
					margin-left: 15px;
				}

				.el-input {
					width: 100%;
				}
			}

			.flex-month {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				justify-content: space-between;

				.el-form-item {
					width: calc(50% - 10px);
				}

				.el-input {
					width: 100%;
				}
			}
		}
	}
</style>
