<template>
	<div class="index">
		<div class="title">
			{{ $route.meta.title }}
			<i @click="showCourse = !showCourse" class="iconfont icon-question-circle"></i>
		</div>
		<div class="course" v-show="showCourse">
			<p><span class="black">1、为当前企业及成员制定全年拓客计划，包括年度、季度、月度计划。</span></p>
		</div>
		<div class="tabs" style="margin-top: 15px;">
			<el-tabs v-model="activeName" type="card">
				<el-tab-pane label="企业计划" name="1"><company-plan :yearList="yearList" :active="activeName" /></el-tab-pane>
				<el-tab-pane label="成员计划" name="2">
					<keep-alive><member-plan v-if="activeName == '2'" :yearList="yearList" /></keep-alive>
				</el-tab-pane>
			</el-tabs>
		</div>
	</div>
</template>

<script>
import CompanyPlan from './CompanyPlan.vue';
import MemberPlan from './MemberPlan.vue';
import { reqShowDevelopYear } from '@/api/index';
export default {
	components: {
		CompanyPlan,
		MemberPlan
	},
	data() {
		return {
			showCourse: false,
			activeName: '1',
			yearList: []
		};
	},
	created() {
		this.getYear();
	},
	mounted() {
		this.$bus.$on('getYearList', () => {
			this.getYear();
		});
	},
	beforeDestroy() {
		this.$bus.$off('getYearList');
	},
	methods: {
		// 获取下拉列表年份
		getYear() {
			reqShowDevelopYear({
				type: 1
			}).then(res => {
				this.yearList = res.data;
			});
		}
	}
};
</script>

<style lang="less" scoped>
.index {
	width: 100%;
	position: relative;
	overflow: hidden;
	
	
	.el-tab-pane > div {
		margin-bottom: 15px;
	}
}
</style>
