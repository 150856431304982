<template>
	<div class="member">
		<div class="flex">
			<el-select size="small" @change="getList()" v-model="year" placeholder="选择年份进行查询">
				<el-option v-for="(item,key) in yearList" :key="key" :label="item" :value="item">
				</el-option>
			</el-select>
			<el-input size="small" v-model="keyword" style="width: 200px;margin: 0 10px;" placeholder="成员姓名搜索">
			</el-input>
			<el-button size="small" class="btn-blue" @click="handleSearch">查找</el-button>
			<el-button size="small" class="btn-white" @click="keyword = ''">清空</el-button>
			<el-button size="small" class="btn-blue right" @click="()=>{
					type='';
					visible=true;
				}">新建成员计划</el-button>
		</div>
		<div class="table">
			<el-table v-loading="loading"
				:data="tableData" style="width: 100%" :header-cell-style="{
			      color: '#333',
			      fontSize: '14px',
			      backgroundColor: '#F6F7FB',
			      fontWeight: 'normal',
				  textAlign:'center'
			    }" :cell-style="{
					textAlign:'center'
				}">
				<el-table-column label="企业成员">
					<template slot-scope='scope'>
						{{scope.row.staff_name || '--'}}
					</template>
				</el-table-column>
				<el-table-column label="所属部门">
					<template slot-scope='scope'>
						{{scope.row.department || '--'}}
					</template>
				</el-table-column>
				<el-table-column prop="date_year" label="所属年度">
				</el-table-column>
				<el-table-column prop="year_target" label="年度计划">
				</el-table-column>
				<el-table-column label="季度计划">
					<el-table-column prop="one_quarter" label="第一季度">
					</el-table-column>
					<el-table-column prop="two_quarter" label="第二季度">
					</el-table-column>
					<el-table-column prop="three_quarter" label="第三季度">
					</el-table-column>
					<el-table-column prop="four_quarter" label="第四季度">
					</el-table-column>
				</el-table-column>
				<el-table-column label="月度计划">
					<el-table-column prop="one_month" label="1月">
					</el-table-column>
					<el-table-column prop="two_month" label="2月">
					</el-table-column>
					<el-table-column prop="three_month" label="3月">
					</el-table-column>
					<el-table-column prop="four_month" label="4月">
					</el-table-column>
					<el-table-column prop="five_month" label="5月">
					</el-table-column>
					<el-table-column prop="six_month" label="6月">
					</el-table-column>
					<el-table-column prop="seven_month" label="7月">
					</el-table-column>
					<el-table-column prop="eight_month" label="8月">
					</el-table-column>
					<el-table-column prop="nine_month" label="9月">
					</el-table-column>
					<el-table-column prop="ten_month" label="10月">
					</el-table-column>
					<el-table-column prop="eleven_month" label="11月">
					</el-table-column>
					<el-table-column prop="twelve_month" label="12月">
					</el-table-column>
				</el-table-column>
				<el-table-column label="操作" min-width="100">
					<template slot-scope='scope'>
						<el-button type='text' @click="handleEdit(scope.row)">编辑</el-button>
						<el-button type='text' @click='handleDetail(scope.row)'>激励详情</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div class="pagination" v-if="total!== 0">
			<el-pagination @size-change="(pageSize)=>{limit= pageSize;page=1;getList();}"
				@current-change="(currentPage)=>{page = currentPage;getList();}" :current-page="page"
				:page-sizes="[10, 20, 40, 80]" :page-size="limit" layout="total, sizes, prev, pager, next, jumper"
				:total="total">
			</el-pagination>
		</div>
		<!-- 新增编辑企业计划 -->
		<Edit :visible='visible' @close="handleClose" :row='row' :typeIndex='2' :type='type' @update="handleUpdate" />
		<!-- 计划完成详情 -->
		<Detail :visible='detailVisible' @detailClose="handleCloseDetail" :yearList="yearList" :row="detail" />
	</div>
</template>

<script>
	import {
		reqGetCompanyPlanList
	} from '@/api/index';
	import Edit from './Edit.vue';
	import Detail from './Detail.vue';
	export default {
		props: ['yearList'],
		components: {
			Edit,
			Detail
		},
		data() {
			return {
				keyword: '',
				page: 1,
				limit: 10,
				total: 0,
				tableData: [],
				loading: false,
				year: '',
				visible: false,
				row: '',
				type: '',
				detailVisible: false,
				detail: ''
			}
		},
		created() {
			this.year = new Date().getFullYear();
			this.getList();
		},
		watch: {
			keyword(val) {
				if (!val) {
					this.getList();
				}
			},
			yearList(val) {
				if (val.length >= 1) {
					val.some(item => {
						return item == this.year
					}) ? '' : this.year = val[0];
				}
			}
		},
		methods: {
			getList() {
				this.loading = true;
				reqGetCompanyPlanList({
					type: 2,
					keyword: this.keyword,
					page: this.page,
					limit: this.limit,
					date_year: this.year
				}).then(res => {
					this.tableData = res.data;
					this.loading = false;
				})
			},
			handleSearch() {
				if (this.keyword) {
					this.getList();
				}
			},
			// 关闭新增编辑弹窗
			handleClose() {
				this.visible = false;
			},
			// 关闭完成详情弹窗
			handleCloseDetail() {
				this.detailVisible = false;
			},
			// 更新列表数据
			handleUpdate() {
				this.getList();
				this.$bus.$emit('getYearList');
			},
			// 计划详情
			handleDetail(row) {
				this.detail = row;
				this.detailVisible = true;
			},
			// 编辑计划
			handleEdit(row) {
				this.row = JSON.parse(JSON.stringify(row));
				this.row.staff_user_id = [row.staff_id];
				this.row.complete_push_staff = row.complete_push_staff.split(',');
				delete this.row.achieve_time;
				delete this.row.grant_time;
				delete this.row.create_at;
				delete this.row.update_at;
				this.type = 'edit';
				this.visible = true;
			}
		}
	}
</script>

<style lang='less' scoped>
	.member {
		width: 100%;
		overflow: hidden;

		.flex {
			display: flex;
			align-items: center;
		}

		>div+div {
			margin-top: 15px;
		}
	}
</style>
